@import "./var.scss";

.container {
  padding-left: var(--padding-gutter);
  padding-right: var(--padding-gutter);

  @screen lg {
    margin-left: auto;
    margin-right: auto;
    max-width: theme('screens.lg');
    width: 100%;
  }
}

.card {
  @apply rounded shadow-8 bg-card;
}

.avatar {
  @apply h-10 w-10 object-cover rounded-full bg-hover;
}

.hidden-input {
  @apply border-2 border-solid border-transparent py-1 px-3 rounded;

  &:focus {
    @apply border-primary outline-none;
  }

  &.empty {
    @apply border-primary;
  }
}

.list-item {
  @apply rounded h-12 px-4 cursor-pointer;
}

.list-item:hover {
  @apply bg-hover;
}

.textarea-clean {
  resize: none;

  &:focus {
    outline: none;
  }
}

.bg-pattern {
  background: #1B7FED;
  //background: linear-gradient(to bottom, #0692d3 0%, #86b804 100%);;
}

// Transitions

.trans-ease-out {
  transition: $swift-ease-out;
}

.trans-shadow {
  transition: box-shadow $mat-elevation-transition-duration $mat-elevation-transition-timing-function;
}

.vex-blogpost {
  p {
    @apply my-3;
  }
}
